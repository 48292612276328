<template>
  <b-modal
    v-model="showPaiementModal"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    size="lg"
    v-if="showPaiementModal"
  >
    <div class="d-block text-center">
      <h4>
        Abonnement aux services myHubcast
      </h4>
    </div>
    <div class="mt-5 mb-2 px-2">
      <b-row v-if="confirmLegalInformations">
        <b-col cols="12" class="px-4 pb-2">
          <p>
            J'autorise Radioshop à prélever la somme de
            <span class="text-primary">{{ amount }}€ HT</span>
            {{ facturationPeriod }} sur ma carte bancaire suivante.<br />Les
            données bancaire seront stockées directement chez le partenaire
            Stripe. Aucune donnée de paiement n'est conservée chez Radioshop.
          </p>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-col cols="12" class="px-4">
          <form @submit="addCustomerCard" id="payment-form" ref="form">
            <div id="payment-element"></div>

            <img
              v-if="confirmLegalInformations"
              src="@/assets/img/powered_by_stripe.svg"
              alt="Powered by Stripe"
              width="120"
              class="mt-3"
            />
            <div v-if="confirmLegalInformations" class="d-flex mt-3">
              <h-button
                @click="$emit('close')"
                outline
                red
                class="ml-auto px-3 mr-3"
              >
                Annuler
              </h-button>

              <h-button type="submit" class="px-3" green>
                Confirmer mon abonnement
              </h-button>
            </div>
          </form>
        </b-col>
      </b-row>
    </div>
    <div v-if="!confirmLegalInformations && currentSite">
      <b-row>
        <b-col cols="12" class="px-4 pb-2">
          <h-input
            v-model="currentSite.name"
            :value-prop="user.visibleBrands[0].name"
            :errorState="$v.currentSite.name.$error"
            v-on:keyup.enter="saveLegalInformations"
            error-feedback="Ce champs est obligatoire"
            textLight
            label="Raison sociale à facturer"
          ></h-input>
        </b-col>
        <b-col cols="12" class="px-4 pb-2">
          <h-input
            v-model="currentSite.siret"
            :errorState="$v.currentSite.siret.$error"
            textLight
            :value-prop="currentSite.siret"
            v-on:keyup.enter="saveLegalInformations"
            label="Numéro SIRET"
            :error-feedback="
              !$v.currentSite.siret.required
                ? 'Ce champs est obligatoire'
                : 'Le numéro SIRET n\'est pas valide'
            "
          ></h-input>
        </b-col>
        <b-col
          cols="12"
          class="px-4 pb-2"
          v-if="currentSite.country.toLowerCase() !== 'france'"
        >
          <h-input
            v-model="currentSite.tvaIntracom"
            :value-prop="currentSite.tvaIntracom"
            :errorState="$v.currentSite.tvaIntracom.$error"
            v-on:keyup.enter="saveLegalInformations"
            textLight
            error-feedback="Ce champs est obligatoire"
            label="Numéro TVA intracommunautaire"
          ></h-input>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-col cols="12" class="px-4">
          <div class="d-flex mt-3">
            <h-button
              @click="$emit('close')"
              outline
              red
              class="ml-auto px-3 mr-3"
            >
              Annuler
            </h-button>

            <h-button
              :disabled="siteInfoLoading"
              @click="saveLegalInformations"
              class="px-3"
              green
            >
              {{
                siteInfoLoading
                  ? 'Enregistrement...'
                  : 'Confirmer mes informations'
              }}
            </h-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import stripeService from '@/services/stripeService'

import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
const siretValid = (value) => {
  if (value.length !== 14) return false
  let sum = 0
  let tmp
  let valid

  for (var cpt = 0; cpt < value.length; cpt++) {
    if (cpt % 2 == 0) {
      // Les positions impaires : 1er, 3è, 5è, etc...
      tmp = value.charAt(cpt) * 2 // On le multiplie par 2
      if (tmp > 9) tmp -= 9 // Si le résultat est supérieur à 9, on lui soustrait 9
    } else tmp = value.charAt(cpt)
    sum += parseInt(tmp)
  }
  if (sum % 10 == 0) valid = true
  // Si la sum est un multiple de 10 alors le SIRET est valide
  else valid = false

  return valid
}

export default {
  props: {
    showPaiementModal: Boolean,
    amount: Number,
    currentCampaign: Object,
    stripeCustomer: String,
  },

  data() {
    return {
      confirmLegalInformations: false,
      legalInformations: {
        companyName: '',
        siret: '',
        tva: '',
      },
      publishableKey:
        'pk_live_51L0SqSIOStoHCRM9GIFS7WfAgri7Gy8aK0SzoF1SHI0utVGnlk7XAOctpnoXwc1hWOZ26O93qcqqH3nd4wiHToVl001SHFLWiP',
      elementsOptions: {
        appearance: {
          theme: 'stripe',
        }, // appearance options
        customerSetupIdent: null,
        elements: null,
        setupIntent: null,
      },
      elements: null,
      currentSite: null,
      siteInfoLoading: false,
    }
  },
  async mounted() {
    const site = await stripeService.getSite(this.selectedSite.id)
    this.currentSite = site.data

    const customer = {
      Email: this.user.email,
      Name: this.user.firstname + ' ' + this.user.lastname,
      Description: `campaign code ${this.currentCampaign.code} from ${this.currentCampaign.bName}/${this.currentCampaign.cSiteCol[0].idSite}`,
    }
    if (!this.stripeCustomer) {
      const customerRes = await stripeService.createStripeCustomer(customer)
      this.$store.commit('stripe/SET_CUSTOMER_ID', customerRes.data)
    }
  },
  computed: {
    facturationPeriod() {
      const period = this.$store.state.campaign.campaign
        .subscriptionCampaignPaymentDetail.libPayment
      const facturationPeriods = {
        MENS: 'chaque mois',
        TRIM: 'chaque trimestre',
        SEM: 'chaque semestre',
        ANU: 'chaque année',
      }

      return facturationPeriods[period]
    },
    ...mapState({
      user: (state) => state.user.user,
      selectedSite: (state) => state.user.selectedSite,
    }),
  },
  validations: {
    currentSite: {
      name: { required },
      siret: { required, siretValid },
    },
  },
  methods: {
    async createCusSetupIdent() {
      const setupIntent = await stripeService.createStripeSetupIdent(
        {
          Id: this.stripeCustomer,
        },
        this.user.visibleBrands[0].id,
        this.selectedSite.id
      )
      this.elementsOptions.customerSetupIdent = setupIntent.data
    },
    async createStripeForm() {
      await this.createCusSetupIdent()
      this.elements = this.$stripe.elements({
        clientSecret: this.elementsOptions.customerSetupIdent,
      })
      const paymentElement = this.elements.create('payment')
      paymentElement.mount('#payment-element')
    },
    async saveLegalInformations() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        try {
          this.siteInfoLoading = true
          await stripeService.postSite(this.selectedSite.id, this.currentSite)
          this.createStripeForm()
          this.confirmLegalInformations = true
        } catch (e) {
          this.$toast.error(
            "Une erreur s'est produite, veuillez réessayer ultérieurement"
          )
        } finally {
          this.siteInfoLoading = false
        }
      }
    },
    async addCustomerCard(evt) {
      evt.preventDefault()
      const elements = this.elements
      const { error } = await this.$stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/abonnement?success=true`,
        },
      })

      if (error) {
        // eslint-disable-next-line no-console
        console.log('Le paiement a échoué')
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
