<template>
  <b-container>
    <HPageTitle>
      Mon abonnement
      <!-- et factures -->
    </HPageTitle>
    <SubscriptionCard />
  </b-container>
</template>

<script>
import SubscriptionCard from '@/components/user/SubscriptionCard'
export default {
  components: {
    SubscriptionCard,
  },
}
</script>

<style lang="scss" scoped></style>
