import client from './axios.js'

export default {
  createStripeCustomer(params) {
    return client.post('/stripe/customer', params)
  },
  createStripeSetupIdent(params, idBrand, idSite) {
    return client.post(
      `/stripe/customer/setupident?idBrand=${idBrand}&idSite=${idSite}`,
      params
    )
  },
  getSite(id) {
    return client.get(`/site/${id}`)
  },
  postSite(id, site) {
    return client.post(`/site/${id}`, site)
  },
  getWarehouse(idSite, idBrand) {
    return client.get(`/site/warehouse?idSite=${idSite}&idBrand=${idBrand}`)
  },
  postWarehouse(idSite, idBrand, jsonStripe, codeCampaign) {
    return client.post(
      `/site/warehouse?idSite=${idSite}&idBrand=${idBrand}&codeCampaign=${codeCampaign}`,
      jsonStripe
    )
  },
}
